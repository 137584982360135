<template>
  <div class="d-flex flex-column">
    <a href="/"><div class="chooseBlock__logoCompany"></div></a>
    <div class="chooseBlock__shortText">Онлайн-сервис для быстрого поиска предмета лизинга</div>
    <div class="mt-6 text-h6">Если Вы хотите стать партнером онлайн-сервиса ПоискПЛ заполните форму ниже
      Мы свяжемся с Вами</div>
    <div>
      <v-form
        ref="form"
        v-model="formValid"
      >
        <v-row class="mt-4">
          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              label="Укажите Ваши ФИО"
              outlined
              @keyup="validate"
              v-model="name"
              :error-messages="errors.name"
              hide-details="auto"
              :rules="nameRules"
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              label="Укажите Ваш контактный номер телефона"
              v-model="phone"
              :error-messages="errors.phone"
              :rules="[v => !!v || 'Обязательное поле', v => (v && v.length <= 20) || `Максимальная длина 20 символов`]"
              outlined
              @keyup="validate"
              hide-details="auto"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              label="Укажите Ваш адрес корпоративной электронной почты"
              v-model="email"
              :error-messages="errors.email"
              :rules="emailFieldRules"
              outlined
              @keyup="validate"
              hide-details="auto"
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              label="Укажите наименование компании"
              v-model="company"
              :error-messages="errors.company"
              :rules="[v => !!v || 'Обязательное поле', v => (v && v.length <= 100) || `Максимальная длина 100 символов`]"
              outlined
              @keyup="validate"
              hide-details="auto"
              required
              ></v-text-field>
          </v-col>
        </v-row>
      <v-row
        v-if="user_role_id==2"
      >
        <v-col
          cols="6"
          md="6"
        >
          <v-text-field
            label="Введите желаемый пароль"
            outlined
            v-model="password"
            :rules="[v => !!v || 'Обязательное поле']"
            :error-messages="errors.password"
            required
            hide-details="auto"
            @click="errors.password=null"
            ></v-text-field>
          </v-col>
          <v-col
              cols="6"
              md="6"
            >
              <v-text-field
                label="Укажите должность в компании"
                v-model="position"
                :rules="[v => !!v || 'Обязательное поле']"
                outlined
                hide-details="auto"
                @click="errors.position=null"
                required
              >
              </v-text-field>
            </v-col>
          </v-row><v-row
            v-if="user_role_id==2"
          >
            <v-col
              cols="6"
              md="6"
            >
              <v-text-field
                label="Повторите ввод пароля"
                outlined
                v-model="password_confirmation"
                :rules="[v => !!v || 'Обязательное поле']"
                :error-messages="errors.password_confirmation"
                required
                hide-details="auto"
                @click="errors.password_confirmation=null"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              md="6"
            >
              <v-text-field
                label="Укажите адрес компании"
                v-model="company_address"
                :rules="[v => !!v || 'Обязательное поле']"
                :error-messages="errors.company_address"
                outlined
                hide-details="auto"
                @click="errors.company_address=null"
                required
              >
              </v-text-field>
            </v-col>
        </v-row>
        <div style="width:375px; margin: 0 auto">
          <div class="text-caption text-center">
            Нажимая на кнопку “Отправить”, <a href="/legal/rules_for_using" target="_blank">Вы соглашаетесь <br/>
            на обработку своих персональных данных</a>
          </div>
          <div class="mt-6 text-center">
            <v-btn height="56" elevation="0" color="primary" block small   :disabled="!formValid" @click="sendMessage">Отправить</v-btn>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import TableMixin from '@/mixins/TableMixin.vue'
// import SiteServiceApi from '@/apis/Site/ServiceApi'
import CompanyApi from '@/apis/CompanyApi'
// import RezervLcModal from '@/views/RequestLeasObjects/RequestLeasObcjectsDetails/components/RezervLсModal.vue'
export default {
  mixins: [TableMixin],
  components: {
    // RezervLcModal
  },
  data() {
    return {
      formValid: false,
      name: null,
      phone: null,
      email: null,
      company: null,
      company_address: null,
      position: null,
      password: null,
      password_confirmation: null,
      nameRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length <= 50) || 'Максимальная длина 100 символов'
      ],
      emailFieldRules: [
        v => !!v || 'Обязательное поле',
        v => /.+@.+\..+/.test(v) || 'E-mail не корректный',
        v => (v && v.length <= 50) || 'Максимальная длина 50 символов'
      ],
      userType: null,
      // token: import.meta.env.VITE_APP_DADATA_API_KEY,
      token: '822ffff4f61dddf228933c56064d7d3697bfa561',
      errors: [],
      city: '',
      coordinates: {
        latitude: '',
        longitude: ''
      },
      suggestionOptions: {
        // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454318
        token: '822ffff4f61dddf228933c56064d7d3697bfa561',
        type: 'ADDRESS',
        scrollOnFocus: false,
        triggerSelectOnBlur: false,
        triggerSelectOnEnter: false,
        addon: 'none',
        // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454320
        onSelect(suggestion) {
            console.log(suggestion)
        }
      }
    }
  },

  watch: {},
  computed: {
      user_role_id: function () {
        return Number.parseInt(this.$route.params.user_role_id)
      }
    },

  methods: {
    sendMessage() {
      if (this.validate) {
        this.$setLoading(true)
        const params = {
          name: this.name,
          phone: this.phone,
          email: this.email,
          company: this.company,
          company_address: this.company_address,
          position: this.position,
          password: this.password,
          password_confirmation: this.password_confirmation,
          user_role_id: this.user_role_id
        }
        CompanyApi.register(params)
          .then(async ({ data }) => {
            console.log({ data })
            if (!data.response.data.errors.length && !data.response.data.exception.length) {
              this.name = this.phone = this.email = this.company = null
              this.showSuccessSystemNotification('Ваше сообщение успешно отправлено')
              this.showSuccessSystemNotification('Ваш аккаунт на модерации')
              this.$router.push({ name: 'Home' })
            }
          })
          .catch(data => {
            console.log({ data })
            if (data.response.data.errors) {
              this.errors = data.response.data.errors
            }
            if (data.response.data.exception) {
              this.showErrorSystemNotification('Произошла ошибка обратитесь в службу поддержки')
            }
          })
          .finally(() => {
            this.$setLoading(false)
          })
      }
    },
    validate () {
      this.errors = []
      this.$refs.form.validate()
    }
  }
}
</script>
